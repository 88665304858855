import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProgressTracker } from '@pbm-shared/pbm-shared-models';
import { map, of, tap, forkJoin} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetricService {
  rootUrl: string;
  private clientGoLiveData: {[key: string]: any} = {};
  private clientProgressTracker: {[key: string]: any} = {};

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') public env: any
  ) {
    this.rootUrl = env.progressTrackerApiUrl
  }

  public getGoLiveDate(clientID: string) {
    if(this.clientGoLiveData[clientID]) {
      return of(this.clientGoLiveData[clientID]);
    }
    return this.http.get<any>(`${this.rootUrl}/golivedate/fetch?clientId=${clientID}`).pipe(tap(value=> {
      this.clientGoLiveData[clientID] = value;
    }))
  }

  public getProgressTracker(clientID: string) {
    if(this.clientProgressTracker[clientID]) {
      return of(this.clientProgressTracker[clientID]);
    }
    return this.http.get<ProgressTracker>(`${this.rootUrl}/progresstracker/fetch?clientId=${clientID}`).pipe(tap(value=> {
      this.clientProgressTracker[clientID] = value;
    }))
  }

  public isLiveDateGreaterThanCurrent(liveDateResponse: any) {
    const currentDate = new Date().toISOString().split('T')[0];
    return liveDateResponse.goliveDate && liveDateResponse.goliveDate > currentDate
  }
  public getProgressAndGoLive(clientId: string) {
    let goLiveData;
    return forkJoin([this.getGoLiveDate(clientId), this.getProgressTracker(clientId)]).pipe(map( ([liveDateResponse, progressResponse]) => {
      goLiveData = liveDateResponse
      if(this.isLiveDateGreaterThanCurrent(liveDateResponse)) {
        return progressResponse;
      }
      return null;
    } ), map(traker=> {
      return [goLiveData, traker]
    }));
  }
}
