<div class="app-container-centered">
  <div class="footer-custom">
    <div class="footer-links-above">
      <div class="footer-links">
        <img class="img-family-companies" src="../../assets/images/cvs_health_family_companies.svg" alt="CVS Health family of companies" />
        <ps-link>
          <a target="_blank" slot="anchor" href="https://www.aetna.com/" rel="noreferrer">Aetna</a>
        </ps-link>
        <ps-link>
          <a target="_blank" slot="anchor" href="https://www.caremark.com/" rel="noreferrer">Caremark</a>
        </ps-link>
        <ps-link>
          <a target="_blank" slot="anchor" href="https://www.cvsmediaexchange.com/" rel="noreferrer">CVS Media Exchange</a>
        </ps-link>
        <ps-link>
          <a target="_blank" slot="anchor" href="https://www.silverscript.com/" rel="noreferrer">SilverScript</a>
        </ps-link>
        <ps-link>
          <a target="_blank" slot="anchor" href="https://www.omnicare.com/" rel="noreferrer">Omnicare</a>
        </ps-link>
        <ps-link>
          <a target="_blank" slot="anchor" href="https://www.oakstreethealth.com/?osh_initial_site=cvs&utm_campaign=7018V000001DiUBQA0&utm_medium=affiliates&utm_source=affiliates&utm_vpf=cvs" rel="noreferrer">Oak Street Health</a>
        </ps-link>
        <ps-link>
          <a target="_blank" slot="anchor" href="https://www.signifyhealth.com/" rel="noreferrer">Signify Health</a>
        </ps-link>
      </div>
      <div class="back-to-top-btn">
        <img src="../../assets/images/arrow-up.svg" alt="" />
        <ps-link 
          (click)="backToTop()" 
          (keydown.space)="backToTop()"
          link-href="javascript:;"
        >
          Back to top
        </ps-link>
      </div>
    </div>
    <div class="footer-divider">
      <ps-divider orientation="horizontal" is-decorative="false"></ps-divider>
    </div>
    <div class="footer-links">
      <ps-link>
        <a slot="anchor" routerLink="/terms_of_use">Terms of use</a>
      </ps-link>
      <ps-link>
        <a slot="anchor" routerLink="/accessibility">Accessibility</a>
      </ps-link>
      <ps-link>
        <a slot="anchor" routerLink="/privacy_policy">Privacy policy</a>
      </ps-link>
      <div style="display: none;">
        <ps-card></ps-card>
        <ps-tile></ps-tile>
      </div>
    </div>
    <span class="copyright">Copyright 1999-2024 CVS.com</span>
    <img src="../../assets/images/nabp.svg" class="nabp-img" alt="NABP accredited digital pharmacy">
  </div>
</div>
