import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthorizationService,
  BrandTypes,
  CVSAuthConfig,
  CVSBaseConfig,
} from 'angular-component-library';
import { environment } from 'src/environments/environment';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { ClientSelectionModalComponent } from './client-selection-modal/client-selection-modal.component';
import { ClientService } from './services/client/client.service';
import { filter } from 'rxjs';
import { TaggingService } from '@pbm-shared/pbm-shared-services';
import { UserModel } from '@pbm-shared/pbm-shared-models';
import { formatUserRole } from './shared/adobe-events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(ClientSelectionModalComponent)
  clientSelectionModal: ClientSelectionModalComponent;

  baseConfig: CVSBaseConfig;
  authConfig: CVSAuthConfig;
  isFreshLogin: boolean;

  constructor(
    private clientService: ClientService,
    private authorizedService: AuthorizationService,
    private taggingService: TaggingService
  ) {
    this.isFreshLogin = !!(window.location.href.indexOf('?state=') > -1);

    this.authConfig = environment.authConfig;
    this.baseConfig = {
      homeRoute: '/',
      appName: '',
      unbranded: true,
      brand: BrandTypes.INTERNALPULSE,
      headerPortal: NavigationComponent,
      footerPortal: FooterComponent,
      footerText: ' ', // hide default copyright
    };
  }

  ngOnInit() {
    this.loadMonitoringScripts();
    this.clientService.selectedClient$
      .pipe(filter((client) => client === undefined))
      .subscribe(() => {
        this.clientSelectionModal.openModal();
      });
  }

  ngAfterViewInit() {
    this.authorizedService.getAuthorizedUser().subscribe((user: UserModel) => {
      this.taggingService.pageLoad({
        pageName: 'mypbm|shell',
        pageDetail: 'shell',
        pageCategory: 'mypbm',
      });

      const appRolesArray = user.appRoles
        .map((role, i) => formatUserRole(role))
        .sort();
      const userType = `${user.accessType
        .toLowerCase()
        .replace(/\s/g, '_')}|${appRolesArray.join('|')}`;

      /**
       * CVS Health Colleague
       * user type: consultant, cvs_ colleague, client_user,
       * user role: plan_manager, clinical_manager,admin_role
       */
      if (this.isFreshLogin) {
        this.taggingService.link({
          linkName: 'mypbm|shell|signin_success',
          linkInteraction: '1',
          loginSuccess: '1',
          userType,
          pageName: 'mypbm|shell',
        });
      } else {
        console.log('myPBM track: update user type ', { userType });
        window.digitalData.userType = userType;
      }
    });
  }

  private loadMonitoringScripts() {
    if (null != environment.appDynamicsKey) {
      this.loadAppDynamicsScript();
    }
    if (null != environment.adobeAnalyticsUrl) {
      this.loadAemScript();
    }
  }
  private loadAppDynamicsScript() {
    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.innerHTML =
      'window["adrum-start-time"] = new Date().getTime(); ' +
      '(function(config){ ' +
      'config.appKey = "' +
      environment.appDynamicsKey +
      '"; ' +
      'config.adrumExtUrlHttp = "http://cdn.appdynamics.com"; ' +
      'config.adrumExtUrlHttps = "https://cdn.appdynamics.com"; ' +
      'config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com"; ' +
      'config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com"; ' +
      'config.useHTTPSAlways = true; ' +
      'config.resTiming = {"bufSize": 200, "clearResTimingOnBeaconSend": true}; ' +
      'config.maxUrlLength = 512; ' +
      '})(window["adrum-config"] || (window["adrum-config"] = {}));';
    const script2 = document.createElement('script');
    script2.src = '//cdn.appdynamics.com/adrum/adrum-23.3.0.4265.js';
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script1);
      document.head.appendChild(script2);
    }
  }

  private loadAemScript() {
    const aemScript = document.createElement('script');
    aemScript.src = environment.adobeAnalyticsUrl;
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(aemScript);
    }

    aemScript.onload = () => {
      console.log('adobe analytics ready for tracking:', window['_satellite']);
      this.taggingService.analyticsScriptLoaded();
    };
  }
}
