<div class="legal-info-container">
    <h1 class="heading">Accessibility Statement</h1>
    <h2 class="subheading">Need assistance?</h2>
    <p class="paragraph">We welcome our customers to contact us about any accessibility issues via email at <a href="mailto:accessibility@cvshealth.com">Accessibility@CVSHealth.com</a>. If you have a hearing or speech disability, please call 7-1-1 to utilize Telecommunications Relay Services (TRS).</p>

    <h2 class="subheading">We believe your path to wellness should be barrier-free.</h2>
    <p class="paragraph">Accessibility is a goal of ours that benefits not only our customers/members, but our own families, friends, colleagues and communities. CVS Health offers a number of accommodations to customers, patients, and colleagues in order to make our services accessible to everyone, including individuals with disabilities. We’ve built strong partnerships with community organizations to ensure we’re a proper reflection of the customers/members we serve.
    </p>
    <p class="paragraph">The same goes for our digital experiences. We’ve taken a different approach to digital accessibility. We utilize inclusive practices, usability studies conducted with individuals with disabilities and leverage expertise from our team members with disabilities to design, build and QA our applications. We believe this approach embeds empathy into our product development and allows us to strive to deliver inclusive and robust digital experiences for everyone.
    </p>
    <p class="paragraph">
        <span class="paragraph-new-line">At CVS Health, we recruit and grow diverse teams of experts including native assistive technology users and non-visual designers to guide our innovations.</span>
        
        <span class="paragraph-new-line">At CVS, our digital products are built with heart.</span>

        We strive to meet and exceed best practices and standards in: <a href="https://www.w3.org/TR/WCAG21/" target="_blank" rel="noreferrer">Latest Web Content Accessibility Guidelines (WCAG) A and AA</a>
    </p>
</div>

