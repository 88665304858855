import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor() {}

  replaceEnvironment(): Promise<any> {
    return fetch(`/assets/config/environment.json`).then(async (res) => {
      const environmentJson = await res.json();
      Object.keys(environmentJson).forEach((key: any) => {
        environment[key] = environmentJson[key];
      });
    });
  }
}
