import { Injectable } from '@angular/core';
import { AdobeEventsService } from 'src/app/shared/adobe-events.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public dialogInsertedObserver!: MutationObserver;
  public modalDialog!: HTMLDialogElement;
  public hasOpened: boolean = false;

  constructor(
    private adobeEvents: AdobeEventsService
  ) {}

  // targets close event from ps-modal X close button
  public onClose() {
    if (this.hasOpened) {
      this.hasOpened = false;
      this.adobeEvents.changeClientCloseLink();
    }
  }

  public setModalDialog(modalDialog: HTMLDialogElement): void {
    this.modalDialog = modalDialog;
    this.modalDialog?.showModal();
    this.hasOpened = true;
    if (this.modalDialog?.addEventListener) {
      this.modalDialog.addEventListener('close', () => {
        this.onClose();
      });
    }
  }

  public closeModalDialog(): void {
    if (this.modalDialog) {
      this.hasOpened = false;
      this.modalDialog.close();
    }
  }
}
