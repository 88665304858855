import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AuthenticationService,
  AuthorizationService,
} from 'angular-component-library';
import { BasicUserInfo, ClientModel } from '@pbm-shared/pbm-shared-models';
import { NavigationItem } from './navigation.types';
import { AccessTypeAndNavigationAccess } from '../shared/authorization.model';
import { ClientService } from '../services/client/client.service';
import { ClientSelectionModalComponent } from '../client-selection-modal/client-selection-modal.component';
import { UserManagementService } from '../services/user-management/user-management.service';
import {
  NotificationItem,
  NotificationStatus,
} from '../shared/navigation.model';
import { NotificationService } from '../services/notification/notification.service';
import { switchMap } from 'rxjs';
import { AdobeEventsService } from '../shared/adobe-events.service';
import { PageTitleRoutesEnum } from '../shared/getPageTitle';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: 'navigation.component.html',
  styleUrls: ['navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  [x: string]: any;
  @ViewChild(ClientSelectionModalComponent)
  clientSelectionModal: ClientSelectionModalComponent;

  protected pages: NavigationItem[] = [];
  protected currentRoute = 'dashboard';
  public notifications: NotificationItem[] = [];
  public isUserExpanded: boolean;
  public isNotificationExpanded: boolean;
  public selectedClient: ClientModel;
  public isMultiClient = false;
  public change = '';
  public loggedInUser: BasicUserInfo;
  public notificationTemplate: TemplateRef<any>;
  public isEmailOptedIn: boolean;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private clientService: ClientService,
    public authenticationService: AuthenticationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private userManagementService: UserManagementService,
    private notificationService: NotificationService,
    private adobeEvents: AdobeEventsService
  ) {
    this.matIconRegistry.addSvgIcon(
      `notification-trash`,
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/notification-trash.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `profile-circle`,
      domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/profile_encircle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `profile-caret`,
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/caret.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `store-icon`,
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/store-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `bell-black`,
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/bell-black.svg')
    );
    this.clientService.selectedClient$
      .pipe(
        distinctUntilChanged((prev, curr) => prev?.clientCode === curr?.clientCode),
        switchMap((value) =>
          value?.clientCode || this.notifications.length
            ? ((this.selectedClient = value),
              notificationService
                .getUserNotifications(
                  this.loggedInUser?.email,
                  value?.clientCode
                )
                .pipe())
            : []
        )
      )
      .subscribe((value) => this.setNotifications(value || []));

    this.clientService.multiClient$.subscribe((data) => {
      this.isMultiClient = <boolean>data;
      this.change = this.isMultiClient === true ? 'Change' : '';
    });
  }

  public setNotifications(notifications: NotificationItem[]) {
    if (notifications) this.notifications = notifications;
  }

  public clearNotifications(event: Event) {
    this.adobeEvents.headerNavNotificationSubLink('clear');
    event.stopPropagation(),
      this.notificationService
        .clearAllNotifications(
          this.loggedInUser?.email,
          this.selectedClient?.clientCode
        )
        .subscribe((value) => (this.notifications = value));
  }

  public updateNotification(notificationId: string) {
    this.adobeEvents.headerNavNotificationSubLink('delete');
    this.notificationService
      .updateUserNotifications(notificationId, NotificationStatus.READ)
      .subscribe(
        (value) =>
        (this.notifications = this.notifications?.filter(
          (notification) => notification.id !== value
        ))
      );
  }

  public handleRouterEvent(event: RouterEvent) {
    if (event instanceof NavigationEnd) {
      this.currentRoute =
        this.activatedRoute.firstChild?.snapshot.routeConfig?.path;

        const main = document.querySelector('main')
        if(main){
          main.scrollTo(0, 0)
        }
    }
  }

  public routeToLink(route: string, hasChildren: boolean) {
    if (!hasChildren && route) {
      this.trackNavChildClick(route);
      this.router.navigateByUrl(route);
    }
  }

  public ngOnInit() {
    this.authorizationService.getAuthorizedUser().subscribe((user) => {
      this.pages = AccessTypeAndNavigationAccess[user.accessType];
      this.loggedInUser = user.basicUserInfo;
      this.isEmailOptedIn = !!user.emailPreference;
    });

    this.router.events.subscribe((event) =>
      this.handleRouterEvent(event as RouterEvent)
    );
  }

  public onChangeClick() {
    this.adobeEvents.changeClientHeaderLink()
    this.clientSelectionModal.openModal();
  }

  displayChange() {
    this.change = this.isMultiClient === true ? 'Change' : '';
    return this.change;
  }

  onNotificationClick() {
    this.adobeEvents.headerNavNotificationLink();
  }

  onToggleEmails(event: Event) {
    event.stopPropagation();
    // Change immediately for UI responsiveness
    this.isEmailOptedIn = !this.isEmailOptedIn;

    this.userManagementService
      .setUserEmailPreference(this.isEmailOptedIn)
      .subscribe((updatedUser) => {
        // Ensure toggle state reflects state returned from server
        this.isEmailOptedIn = !!updatedUser.emailPreference;
      });
  }

  trackNavLinkClick(route: string) {
    if (route === PageTitleRoutesEnum.Dashboard) {
      this.adobeEvents.headerNavDashboardLink();
    }
    if (route === PageTitleRoutesEnum.Reporting) {
      this.adobeEvents.headerNavReportingLink();
    }
    if (route === PageTitleRoutesEnum.Plandashboard) {
      this.adobeEvents.headerNavBenefitsLink();
    }
    if (route === PageTitleRoutesEnum.Eligibility) {
      this.adobeEvents.headerNavEligibilityLink();
    }
  }

  trackNavButtonClick(label: string){
    if (label === 'Client Info') {
      this.adobeEvents.headerNavClientInfoLink();
    }
    if (label === 'Clinical Plans') {
      this.adobeEvents.headerNavClinicalLink();
    }
  }

  trackNavChildClick(route: string){
    if (route.includes(PageTitleRoutesEnum.Clientcontacts)) {
      this.adobeEvents.headerNavClientInfoSubLink('client_contacts');
      return;
    }
    if (route.includes(PageTitleRoutesEnum.Cvscontacts)) {
      this.adobeEvents.headerNavClientInfoSubLink('cvs_caremark_contacts');
      return;
    }
    if (route.includes(PageTitleRoutesEnum.Vendorcontacts)) {
      this.adobeEvents.headerNavClientInfoSubLink('vendor_contacts');
      return;
    }
    if (route.includes(PageTitleRoutesEnum.Consultantcontacts)) {
      this.adobeEvents.headerNavClientInfoSubLink('consultant_contacts');
    }

    if (route.includes(PageTitleRoutesEnum.Clinical)) {
      this.adobeEvents.headerNavClinicalSubLink('cpm');
      return;
    }
    if (route.includes(PageTitleRoutesEnum.Formularysearch)) {
      this.adobeEvents.headerNavClinicalSubLink('fds');
      return;
    }
  }

  onCloseNotifications() {
    this.adobeEvents.headerNavNotificationSubLink('close');
  }

  getNotificationRedirect(notificationDetail: string):string {
    let path = '';
    const arr = notificationDetail.split('href=');
    if (arr[1]) {
      path = arr[1].split('>')[0] || '';
    }
    // return empty string for invalid paths
    if (!path.includes('document')) {
      path = '';
    }
    return path;
  }

  onViewDocumentClick() {
    this.adobeEvents.headerNavNotificationSubLink('view_document');
  }

}
