/*
DO NOT USE:
In some cases we need to force reload of dashboard module by faking navigation.
This empty component/route exists only for that purpose.
*/

import { Component } from '@angular/core';

@Component({
  selector: 'app-stub',
  template: '',
  styles: []
})
export class StubComponent {

  constructor() { }

}
