import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, forkJoin, from, lastValueFrom, Observable, switchMap, tap} from 'rxjs';
import { ClientModel } from '@pbm-shared/pbm-shared-models';
import { PaginatedResponseModel } from '../../shared/paginated-response.model';
import { environment } from '../../../environments/environment';
import {AuthorizationService} from 'angular-component-library';
import {ClientInfoModel, PaginatedClientInfoModel} from '../../shared/clientinfo.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private _selectedClient: BehaviorSubject<ClientModel> = new BehaviorSubject<ClientModel>(null);
  selectedClient$ = this._selectedClient.asObservable();
  private _allClient: BehaviorSubject<{data: ClientModel[]}> = new BehaviorSubject<{data: ClientModel[]}>(null);
  allClient$ = this._allClient.asObservable();
  private _hasMultiClient : BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(true);
  multiClient$ = this._hasMultiClient.asObservable();

  constructor(
    private httpClient: HttpClient,
    private authorizationService: AuthorizationService,
  ) { }

  getUserClients(): Observable<{data: ClientModel[]}> {
    if(!this._allClient.value) {
      return this.authorizationService.getAuthorizedUser().pipe(
        switchMap(user => {
          const allClients = (user?.assignedPortalClients || []).filter(client=> client.accountLevel === 'ClientCode');
          return from(this.filterE2EClients(allClients))
        }),
        tap(e2eClients => { this._allClient.next(e2eClients) }));
    }
    return this.allClient$;
  }

  async filterE2EClients(allClients): Promise<{data: ClientModel[]}> {
    try {
      const url = environment.clientInfoApiUrl + '/clientInfoes?isMyPBMEndToEndClient=true&page=0&size=500';
      const map: Record<string, boolean> = {};

      const firstPageClients = await lastValueFrom(this.httpClient.get<PaginatedClientInfoModel>(url));
      firstPageClients.content?.forEach((client: ClientInfoModel) => map[client.clientCode] = true);
      const totalPage = firstPageClients.page?.totalPages;
      if (totalPage === 1) {
        return {data: allClients.filter((client: ClientInfoModel) => map[client.clientCode])};
      }

      let urls:  string[] = [];
      for (let currentPage = 1; currentPage < totalPage; currentPage++) {
        urls.push(environment.clientInfoApiUrl + `/clientInfoes?isMyPBMEndToEndClient=true&page=${currentPage}&size=500`);
      }

      const getAllClientsCall = urls.map(url => this.httpClient.get<PaginatedClientInfoModel>(url));
      const nextPageClients = await lastValueFrom(forkJoin(getAllClientsCall));
      nextPageClients.forEach(e2eClientsResult => {
        e2eClientsResult.content.forEach((client: ClientInfoModel) => map[client.clientCode] = true)
      });

      return {data: allClients.filter((client: ClientInfoModel) => map[client.clientCode])};

    } catch (error) {
      return {data: []};
    }
  }

  setClient(selectedClient: ClientModel): void {
    this._selectedClient.next(selectedClient);
  }

  setHasMultiClient(multiClient: boolean){
    this._hasMultiClient.next(multiClient);
  }

}
