<div class="legal-info-container">
    <h1 class="heading">myPBM™ Privacy Policy
        <span class="effective-date">Effective Date: June 6, 2024</span>
    </h1>

    <p class="paragraph">
        This Privacy Policy describes how myPBM™ (“CVS Caremark,” “we” or “us”) may collect information about you through your interactions with us on our websites and mobile applications (collectively, the “Services”). By using the Services, you agree to the terms of this Privacy Policy. If you have any questions or concerns about this Privacy Policy, or about the way your information is collected and used, please call us toll-free at (800) 841-5550.
    </p>
    <p class="paragraph">
        We may change this Privacy Policy. The “Effective Date” legend at the top of this page indicates when this Privacy Policy was last revised. Any changes will become effective when we post the revised Privacy Policy on the Services. Your use of the Services following these changes means that you accept the revised Privacy Policy.
    </p>

    <h2 class="subheading">
        Who May Use the Service
    </h2>

    <p class="paragraph bold">
        Our Service is not intended for children under the age of 18, and therefore, we do not knowingly collect personal information online from any person we know to be under the age of 18 and instruct users under 18 not to send us any information to or through the online Service.
    </p>
    <p class="paragraph">
        The online Services are designed for users from, and are controlled and operated by us from, the United States. By using the Services, you consent to the transfer of your information to the United States or storage of your information in the United States, which may have different data protection rules than those of your country.
    </p>

    <h2 class="subheading">
        The Personal Information We Collect
    </h2>

    <p class="paragraph">
        We want you to understand how personal information you provide to us is collected and used. Personal information is any information that we can use to identify, locate, or contact you. We may collect and store your personal information when you provide it to us or to our service providers. Some examples of personal information we collect and when we collect it include:
    </p> 
    <ul class="list-no-marker">
        <li><span class="bold">Registration:</span> We collect your personal information when you register on the Services.</li>

        <li><span class="bold">Contact Information:</span> We collect personal information from users of the Services.</li>
        
        <li><span class="bold">Device Information:</span> We may automatically collect certain personal device information such as device locale, cellular carrier, device manufacturer and device model for purposes of diagnosing problems and to ensure services function properly.</li>
    </ul>
    <p class="paragraph bold">If you choose not to provide your personal information to us, we may not be able to provide you with requested products, services or information.</p>

    <h2 class="subheading">
        Use and Disclosure of Personal Information
    </h2>

    <p class="paragraph">
        We use your personal information to respond to your requests, such as, send you email alerts, send you newsletters, and to provide you with related customer service. We may also use your information to send administrative information to you.
    </p>
    <p class="paragraph">
        We may use your personal information to personalize your experience on the Website and when interacting with us, including by presenting products and offers tailored to you, and for our business purposes, such as data analysis, audits, fraud monitoring and prevention, developing our Services and new products and services, determining the effectiveness of our educational, awareness or promotional campaigns, and operating and expanding our business activities.
    </p>
    <p class="paragraph">
        In the event that CVS Caremark or some or all of our business, assets or stock are sold or transferred (including in connection with any bankruptcy or similar proceedings) or used as security, or to the extent we engage in business negotiations with third parties, personal information may be transferred to or shared with third parties as part of any such transaction or negotiation.
    </p>
    <p class="paragraph">
        To the extent permitted by applicable law, we may provide personal information to our affiliated businesses or to our business partners, who may use it to send you marketing and other communications.
    </p>
    <p class="paragraph">
        We may disclose personal information to our service providers, who provide services such as website hosting, data analysis, payment processing, order fulfilment, information technology and related infrastructure provision, customer service, email delivery, auditing, and other services.
    </p>
    <p class="paragraph">
        If we are requested by law enforcement officials or judicial authorities to provide personal information, we may do so. In matters involving claims of personal or public safety or in litigation where the information is pertinent (including to allow us to pursue available remedies or limit the damages that we may sustain), we may use or disclose personal information, including without court process. We may also use or disclose personal information to enforce our terms and conditions, to protect our operations or those of any of our affiliates, or to protect our rights, privacy, safety or property and/or that of our affiliates, you, or others.
    </p>
    <p class="paragraph">
        We may use and disclose personal information to investigate security breaches or otherwise cooperate with authorities pursuant to a legal matter.
    </p>
    <p class="paragraph">
        We may use and disclose information that does not personally identify you (including the information described under "Cookies and Other Technologies," below) for any purpose, except to the extent limited by applicable law. If we are required to treat such information as personal information under applicable law, then we may use it for all the purposes for which we use and disclose personal information.
    </p>
    <p class="paragraph">
        We may combine information that does not personally identify you with personal information. If we do, we will treat the combined information as personal information as long as it is combined.
    </p>

    <h2 class="subheading">
        Links
    </h2>

    <p class="paragraph">
        The Services may contain links to, or otherwise make available, third-party websites, services, or other resources not operated by us or on our behalf ("Third Party Services"). These links are provided as a convenience only and do not constitute an affiliation with, endorsement or sponsorship of the Third Party Services. Specifically, the tools and resources section available on the site in connection with the Services are links to Third Party Services.
    </p>
    <p class="paragraph">
        Any information you provide to such third parties is not subject to the terms of this Privacy Policy, and we are not responsible for the privacy or security of the information you provide to them or their handling of your information. We recommend that you review the privacy policy of any third party to whom you provide personal information online.
    </p>
    <p class="paragraph">
        In addition, we are not responsible for the information collection, use, disclosure, or security policies and practices of other organizations, such as Apple, Google, Microsoft, RIM, or any other app developer, app provider, operating system provider, wireless service provider, or device manufacturer.
    </p>

    <h2 class="subheading">
        Security
    </h2>

    <p class="paragraph">
        We seek to use reasonable physical, technical, and administrative safeguards to protect personal information within our organization. Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of your account with us has been compromised), please immediately contact us in accordance with the "Contact Information" section below.
    </p>
    <p class="paragraph">
        You are responsible for maintaining the confidentiality of your Services access information and password and for restricting access to your device, and you agree to accept responsibility for all activities that occur under your password.
    </p>

    <h2 class="subheading">
        Cookies and Other Technologies
    </h2>

    <p class="paragraph">
        Like many other websites and online services, we collect information about the Services’ traffic and usage patterns through the use of cookies, Web server logs, web beacons and other, similar technologies. We use this information for various purposes, such as to ensure that the Services function properly, to facilitate navigation, to personalize your experience, to understand use of the Services, to diagnose problems, to measure the success of our marketing campaigns and to otherwise administer the Services.
    </p>
    <p class="paragraph">
        Cookies are small computer files we transfer to your computer's hard drive. These small text files help us personalize content on our pages and provide programs like e-coupons. Your browser software can be set to reject or accept cookies. Instructions for resetting the browser are available in the Help section of most browsers.
    </p>
    <p class="paragraph">
        Our use of cookies also allows us to collect and retain certain information about a website user, such as the type of Web browser used by our customer. Reviewing our Web server logs and our customers' use of our site helps us to, among other purposes, statistically monitor how many people are using our site and for what purpose.
    </p>
    <p class="paragraph">
        Your IP address is a number that is automatically assigned to the computer that you are using by your Internet Service Provider. An IP address may be identified and logged automatically in our server log files whenever a user accesses the Services, along with the time of the visit and the page(s) that were visited. Collecting IP addresses is standard practice and is done automatically by many websites, applications and other services. We use IP addresses for purposes such as calculating usage levels of the Services, helping diagnose server problems, and administering the Services.
    </p>

    <h2 class="subheading">
        Tracking/Third-Party Advertisers
    </h2>

    <p class="paragraph">
        We do not respond to browser do-not-track signals.
    </p>
    <p class="paragraph">
        We may use analytics providers that use cookies, pixel tags and other, similar technologies to collect information about your use of the Services and your use of other websites or online services.
    </p>

    <h2 class="subheading">
        Fraudulent Sites, Spam & Phishing
    </h2>

    <p class="paragraph">
        Please be aware that there may be fraudulent websites that illegally use CVS Caremark logos, and other aspects of the CVS Caremark brand. CVS Caremark is in no way associated with any fraudulent websites. These sites may circulate their presence on the internet via spam email, or through fraudulent phishing practices.
    </p>
    <p class="paragraph">
        These sites have not been authorized by CVS Caremark to use our name and we work aggressively to identify their source and have them shut down. If you are in receipt of this type of spam email, to help protect your privacy you should avoid replying to it or forwarding it to other people.
    </p>
    <p class="paragraph">
        In addition to our official websites, CVS Caremark works with a number of third parties that host websites and micro-sites that provide information and services to our customers. If you are concerned that a website or an email may be fraudulent, please contact us by phone at (800) 841-5550 with your concerns.
    </p>
    
    <h2 class="subheading">
        Your Choices and Access
    </h2>

    <p class="paragraph">
        You can request the removal or modification of the personal information you have provided to us by sending an email to the appropriate area under "Contact Us". For your protection, we may only implement requests with respect to the personal information associated with the particular email address that you use to send us your request, and we may need to verify your identity and obtain information on the context in which you provided your personal information before implementing your request. We will try to accommodate your request as soon as reasonably practicable.
    </p>
    <p class="paragraph">
        Please note that we may need to retain certain information for recordkeeping purposes. There may also be residual information that will remain within our databases and other records, which will not be removed.
    </p>

    <h2 class="subheading">
        California Shine the Light Law
    </h2>

    <p class="paragraph">
        If you are our customer and a California resident, you may request that we provide you with certain information about the entities with which we have shared our customers' personal information for direct marketing purposes during the preceding calendar year. To do so, please write to us at <a href="mailto:privacyoffice@cvshealth.com">PrivacyOffice@cvshealth.com.</a>
    </p>

    <h2 class="subheading">
        Your Responsibility
    </h2>

    <p class="paragraph">
        By establishing a <a href="https://mypbm.caremark.com">myPBM™</a> account, you agree that it is your responsibility to:
    </p>
    <ul>
        <li>Authorize, monitor, and control access to and use of your myPBM™ account, User ID and password.</li>
        <li>Promptly inform us of any need to deactivate a password or an account by calling Customer Service at (800) 841-5550.</li>
    </ul>

    <h2 class="subheading">
        Contact Information
    </h2>

    <p class="paragraph">
        If you have any questions or concerns about this statement, or about the way your information is collected and used, please call us toll-free at (800) 841-5550.
    </p>
    <p class="paragraph">
        If you have any questions about the content of this Privacy Policy, please contact the CVS Health Privacy Office at the following address:
    </p>
    
   <address>
    <span class="address-line bold">CVS Health</span>
    <span class="address-line">Attn: Privacy Office</span>
    <span class="address-line">1 CVS Drive</span>
    <span class="address-line">Woonsocket, R.I. 02895</span>
    <span class="address-line">(800) 841-5550</span>
   </address>
</div>
