export interface GetNotificationResponse {
  userNotifications: NotificationItem[];
  clientProfile: string;
  count: number;
}

export interface UpdateNotificationResponse {
  clientProfile: string;
  createdTs?: string;
  createdUser?: string;
  detail?: string;
  header: string;
  id: string;
  modfiedTs?: string;
  modifiedUser?: string;
  parentNotificationId?: string;
  status: 'UNREAD' | 'READ' | 'DELETED';
  userEmail?: string;
}

export interface NotificationItem {
  id?: string;
  status?: string;
  notificationSummary?: string;
  notificationDetail?: string;
  userEmail?: string;
}

export enum NotificationStatus {
  READ = 'READ',
  UNREAD = 'UNREAD',
  DELETED = 'DELETED',
}
