import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { PulseCoreAngularModule } from '@digital-pulse/web-components-ng/dist/component-library';
import { AgGridModule } from 'ag-grid-angular';
import {
  CVSAutocompleteSelectModule,
  CVSBannerModule,
  CVSBaseModule,
  CVSInlineCalloutModule,
  CVSLoaderModule,
  TooltipModule
} from 'angular-component-library';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CVSBaseModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    CVSLoaderModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    TooltipModule,
    MatButtonToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ReactiveFormsModule,
    MatButtonModule,
    CVSBannerModule,
    MatSelectModule,
    FormsModule,
    CVSInlineCalloutModule,
    MatTabsModule,
    CVSAutocompleteSelectModule,
    AgGridModule,
    NgxMaskModule,
    PulseCoreAngularModule,
    MatListModule
  ],
  exports: [
    CVSBaseModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    CVSLoaderModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    TooltipModule,
    MatButtonToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ReactiveFormsModule,
    MatButtonModule,
    CVSBannerModule,
    MatSelectModule,
    FormsModule,
    CVSInlineCalloutModule,
    MatTabsModule,
    CVSAutocompleteSelectModule,
    AgGridModule,
    NgxMaskModule,
    PulseCoreAngularModule,
    MatListModule,
  ]
})
export class SharedModule {}
