import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserModel } from '@pbm-shared/pbm-shared-models';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  baseUrl = environment.portalApiUrl + '/userManagement';
  
  constructor(
    private httpClient: HttpClient
  ) { }

  setUserEmailPreference(isOptedInEmail: boolean) {
    const url =
      `${this.baseUrl}/userPreferences/emailPreference?preference=${isOptedInEmail}`;

    return this.httpClient.put<UserModel>(url, {});
  }
}
