import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  GetNotificationResponse,
  NotificationItem,
  UpdateNotificationResponse,
  NotificationStatus,
} from 'src/app/shared/navigation.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public baseUrl = environment.notificationApiUrl + '/notifications';
  constructor(private httpClient: HttpClient) {}

  public getUserNotifications(email: string, clientCode?: string) {
    const url = `${this.baseUrl}/${email}${clientCode ? `/${clientCode}` : ''}`;
    var notifications = new Subject<NotificationItem[]>();
    this.httpClient.get<GetNotificationResponse>(url, {})?.subscribe({
      next: (value) => notifications.next(value?.userNotifications),
      error: () => notifications.next([]),
    });
    return notifications.asObservable();
  }

  public clearAllNotifications(
    email: string,
    clientCode?: string
  ): Observable<NotificationItem[]> {
    const url = `${this.baseUrl}/${email}${clientCode ? `/${clientCode}` : ''}`;
    let notifications = new Subject<NotificationItem[]>();
    this.httpClient.delete<any>(url, {})?.subscribe({
      next: () => notifications.next([]),
      error: (err) => console.error('error:', err),
    });

    return notifications.asObservable();
  }

  // Marks Notification as Deleted, removes from notifications ui
  public updateUserNotifications(
    notificationId: string,
    notificationStatus: NotificationStatus
  ) {
    let updatedNotificationId = new Subject<string>();
    this.httpClient
      .patch<UpdateNotificationResponse>(this.baseUrl, {
        id: notificationId,
        status: notificationStatus,
      })
      ?.subscribe({
        next: (value) => updatedNotificationId.next(value?.id),
        error: (err) => (
          updatedNotificationId.next(''), console.error('error:', err)
        ),
      });
    return updatedNotificationId.asObservable();
  }
}
