import {Component} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent{

  constructor() {}

  backToTop(){
    const main = document.querySelector('main')
    main.scrollTo({top: 0})
  }
}
