import { Environment } from '../app/shared/environment.model';
import { CVSAuthConfig } from 'angular-component-library';

export const environment: Environment = {
  production: false,
  currentEnv: 'local',
  authConfig: {
    authorizationUrl: 'https://dev.api.mypbm-np.caremark.com/portal-api-dev/api/v1/auth/authorizedUser/e2e',
    ssoBaseUrl: 'https://ngamypbmnonprod.b2clogin.com/ngamypbmnonprod.onmicrosoft.com',
    issuer: 'https://ngamypbmnonprod.b2clogin.com/2a2762e7-d56f-47ab-a72a-df0102c4579a/v2.0/',
    clientId: '2b118e9a-44c9-4b8c-b26e-6955159050f9',
    scope: 'openid offline_access',
    tokenUrl: 'https://ngamypbmnonprod.b2clogin.com/ngamypbmnonprod.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1A_signup_signin',
    accessDeniedMessage: 'It appears as though you do not have access to use this application.',
    authenticationEnabled: true,
    useAccessToken: false,
    idle: 1800,
    timeOut: 60,
    loginUrl: '/B2C_1A_signup_signin/oauth2/v2.0/authorize',
    logoutUrl: '/B2C_1A_signup_signin/oauth2/v2.0/logout'
  } as CVSAuthConfig,
  agGridLicense: 'Using_this_{AG_Grid}_Enterprise_key_{AG-053981}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{CVS_Pharmacy,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{myPBM}_only_for_{27}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{myPBM}_need_to_be_licensed___{myPBM}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_May_2025}____[v3]_[01]_MTc0NzAwNDQwMDAwMA==c6e179940c1f6d96d88db1c7eb6a0d40',
  appDynamicsKey: 'AD-AAB-ACV-AFE',
  adobeAnalyticsUrl: 'https://assets.adobedtm.com/99b399019383/0da3a52f37db/launch-b73104a81b43-development.min.js',
  portalApiUrl: 'https://dev.api.mypbm-np.caremark.com/portal-api-dev/api/v1',
  apiBaseUrl: 'https://cob-api-dev.client.mypbm-np.caremark.com',
  cifRestApiUrl: 'https://cif-api-dev.client-aks-usc.mypbm-np.caremark.com',
  planRepoApiUrl: 'https://uat.api.mypbm-np.caremark.com/cpr-repo-api-uat',
  ewfApiUrl: 'https://dev.api.mypbm-np.caremark.com/ewf-api-dev',
  metricApiUrl: 'https://dev.api.mypbm-np.caremark.com/metric-api-dev',
  cciApiUrl: 'https://cci-api-dev.client-aks-usc.mypbm-np.caremark.com',
  cprApiUrl: 'https://uat.api.mypbm-np.caremark.com/cpr-api-uat',
  scpmApiUrl: 'https://scpm-api-stress.clinical.mypbm-np.caremark.com',
  docApiUrl: 'https://dev.api.mypbm-np.caremark.com/document-api-dev/',
  fdsApiUrl: 'https://dev.api.mypbm-np.caremark.com/token-wrapper-dev',
  notificationApiUrl: 'https://dev.api.mypbm-np.caremark.com/notification-api-dev/v1',
  progressTrackerApiUrl: 'https://dev.api.mypbm-np.caremark.com/caarxc-api-dev/v1/e2e',
  clientInfoApiUrl: 'https://dev.api.mypbm-np.caremark.com/clientinfo-api-dev'
};
