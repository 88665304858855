import { Injectable } from '@angular/core';

import { catchError, EMPTY, Observable } from 'rxjs';
import { ShellContext } from '@pbm-shared/pbm-shared-models';
import { ShellContextService } from './services/shell-context/shell-context.service';

@Injectable({
  providedIn: 'root'
})
export class ShellContextResolver  {

  constructor(
    private contextService: ShellContextService
  ) { }

  resolve(): Observable<any> {
    return this.contextService.getContext().pipe(
      // TODO: add more robust error handling
      // for now we are allowing errors to bubble up to parent
    );
  }
}
