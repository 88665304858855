import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from 'angular-component-library';
import { map, Observable, of, switchMap } from 'rxjs';
import { AccessTypeAndNavigationAccess } from '../shared/authorization.model';
import { ClientService } from '../services/client/client.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
    private clientService: ClientService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const stateUrl = state.url.split('?')[0];
    const clientCode = route.paramMap.get('clientCode');
    let user;
    return this.authorizationService.getAuthorizedUser().pipe(switchMap(userInfo=> {
      if(!userInfo) {
        return of(null);
      }
      user = userInfo
      return this.clientService.getUserClients()
    }), map(responseClients=> {
      if(!responseClients) {
        return false;
      }

      const allClients = responseClients.data
      let selectedClient = allClients.find(client=> client.clientCode === clientCode);
      if(allClients.length > 1) {
        this.clientService.setHasMultiClient(true)
      } else {
        this.clientService.setHasMultiClient(false);
      }
      if(selectedClient) {
        this.clientService.setClient(selectedClient);
      } else if (allClients.length === 1){
        this.clientService.setClient(allClients[0]);
        selectedClient = allClients[0];
      } else {
        this.clientService.setClient(undefined);
        return false;
      }
      const isValidRoute = route.url.some(segment => {
        return AccessTypeAndNavigationAccess[user.accessType].some(
          access => {
            return  access.route === segment.path || (access.children || []).some(
              child => {
                // segment path is only the first segment of the url, so for children whose parent has
                // no route, we need to look at the router state's url.
                return `/${clientCode}/${child.route}` === stateUrl || (child.children || []).some(x=>
                  state.url === `/${clientCode}/${x.route}`);
              }
            )
          }
        )
      });

      if (!isValidRoute) {
        const clientCodeForNav = clientCode || selectedClient?.clientCode;
        this.router.navigate([clientCodeForNav, 'dashboard']);
        return false;
      }
      return isValidRoute;
    }));
  }
}
