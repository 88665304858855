export const DefaultProgressTracker = [
  {
    "functional_area": "Benefits",
    "phase": {
      "discovery": "Not Completed",
      "build": "Not Completed",
      "test": "Not Completed",
      "deploy": "Not Completed"
    }
  },
  {
    "functional_area": "Eligibility",
    "phase": {
      "discovery": "Not Completed",
      "build": "Not Completed",
      "test": "Not Completed",
      "deploy": "Not Completed"
    }
  },
  {
    "functional_area": "Billing Setup",
    "phase": {
      "discovery": "Not Completed",
      "build": "Not Completed",
      "test": "Not Completed",
      "deploy": "Not Completed"
    }
  },
  {
    "functional_area": "Welcome Materials",
    "phase": {
      "discovery": "Not Completed",
      "build": "Not Completed",
      "test": "Not Completed",
      "deploy": "Not Completed"
    }
  }
];
