import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {catchError, concatMap, map, Observable, of} from 'rxjs';
import {MetricService} from './services/metric/metric.service';
import {ClientService} from './services/client/client.service';
import {ProgressContext, ProgressEntry, TrackerDetail} from '@pbm-shared/pbm-shared-models';
import {DefaultProgressTracker} from './shared/defaultProgressTracker';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';

@Injectable({
  providedIn: 'root'
})
export class ProgressContextResolver implements Resolve<ProgressContext> {

  constructor(
    private metricService: MetricService,
    private clientService: ClientService,
    private bannerService: CVSBannerService
  ) { }

  getClientAndGoLive() {
    return this.clientService.selectedClient$.pipe(
      concatMap(client => {
        return this.metricService.getProgressAndGoLive(client.clientUniqueId)
    }))
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProgressContext> {
    let tracker = {trackerDetails: DefaultProgressTracker}
    return this.getClientAndGoLive().pipe(
      map((responses) => {
        const goLiveDate = responses[0].goliveDate;
        let progressTracker = [];
     if (responses[1]?.progressTracker) {
       tracker = responses[1]?.progressTracker[0];
     } else if (this.metricService.isLiveDateGreaterThanCurrent(responses[0]) && !route.url.some(x=> x.path === "reporting") ) {
       setTimeout(() => {
         this.bannerService.sendAlert({bannerType: CVSBannerType.Error})
       },)
     }

        if(tracker) {
          const { trackerDetails } = tracker;
          progressTracker = this.formatProgressByPhase(trackerDetails);
        }
        const goLiveTracker = {
          goLiveDate,
          goLiveDisplayDate: this.getGoLiveDisplayDate(goLiveDate)
        };
        return {
          goLiveTracker,
          progressTracker
        }
      }),
      catchError(x=> {
        setTimeout(()=> {
          this.bannerService.sendAlert({bannerType: CVSBannerType.Error})
        }, )
        return of({goLiveTracker: {goLiveDate: '', goLiveDisplayDate: ''}, progressTracker: this.formatProgressByPhase(tracker.trackerDetails)})
      })
    )
  }

  formatProgressByPhase = (trackerDetails: TrackerDetail[]): ProgressEntry[] => {
    return trackerDetails.map(detail => ({
      title: detail.functional_area,
      currentPhase: this.getPhaseNumberFromDetail(detail.phase)
    }))
  }

  getGoLiveDisplayDate(goLiveDate: string): string {
    // Comes in as yyyy-MM-dd
    const goLiveSplit = goLiveDate.split('-');
    return goLiveSplit[1] + '/' + goLiveSplit[2] + '/' + goLiveSplit[0];
  }

  isCompleted = (status: string): boolean => {
    return status.toUpperCase() === 'COMPLETED'
  }

  getPhaseNumberFromDetail = (phase: {
    discovery: string;
    deploy: string;
    build: string;
    test: string;
  }
  ): number => {
    if (this.isCompleted(phase.deploy)) {
      return 4;
    }
    if (this.isCompleted(phase.test)) {
      return 3;
    }
    if (this.isCompleted(phase.build)) {
      return 2;
    }
    if (this.isCompleted(phase.discovery)) {
      return 1;
    }
    return 0;
  }
}
