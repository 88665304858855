import { Injectable } from '@angular/core';
import { LinkEvent, PageLoadEvent, TaggingService } from '@pbm-shared/pbm-shared-services';

const HEADER_NAV = 'mypbm|header|'
const HEADER_NAV_CLIENT_INFO = `${HEADER_NAV}client_info`

type ClientInfoSubLink = 'client_information'
  | 'client_contacts'
  | 'cvs_caremark_contacts'
  | 'vendor_contacts'
  | 'consultant_contacts';

export const formatUserRole = (role: string) => {
  let tagRole = role.toLowerCase();
  if (tagRole.startsWith('e2e_')) {
    tagRole = tagRole.slice(4);
  }
  return tagRole;
}


@Injectable({
  providedIn: 'root'
})
export class AdobeEventsService {

  constructor(
    private taggingService: TaggingService
  ) { }

  /* 
  Client Selection Section
  https://cvsdigital.atlassian.net/wiki/spaces/EMT/pages/3472392467/myPBM+Web+Tag+Spec#Client-Selection
  */

  // Page Load
  // https://cvsdigital.atlassian.net/wiki/spaces/EMT/pages/3472392467/myPBM+Web+Tag+Spec#Page-Load-Tracking.7
  public clientSelectionPageLoad() {
    const digitalData: PageLoadEvent = {
      pageName: 'mypbm|client_selection_overlay',
      pageDetail: 'client_selection_overlay',
      pageCategory: 'mypbm'
    };
    this.taggingService.pageLoad(digitalData);
  }

  // Link: On click of ‘open dashboard’
  // https://cvsdigital.atlassian.net/wiki/spaces/EMT/pages/3472392467/myPBM+Web+Tag+Spec#Link-Tracking.6
  public clientSelectionGoToDashboardLink() {
    const digitalData: LinkEvent = {
      linkName: 'mypbm|client_selection_overlay|open_dashboard',
      linkInteraction: '1',
      pageName: 'mypbm|client_selection_overlay'
    };
    this.taggingService.link(digitalData);
  }

  /*
  Header Links
  https://cvsdigital.atlassian.net/wiki/spaces/EMT/pages/3472392467/myPBM+Web+Tag+Spec#Header-Links
  */

  // Link: On click of ‘change’
  // https://cvsdigital.atlassian.net/wiki/spaces/EMT/pages/3472392467/myPBM+Web+Tag+Spec#Link-Tracking.43
  public changeClientHeaderLink(pageName?: string) {
    const digitalData: LinkEvent = {
      linkName: 'mypbm|change_client',
      linkInteraction: '1',
      pageName,
      overlayName: 'change_client'
    };
    this.taggingService.link(digitalData);
  }

  // Link: On load of overlay ‘change’
  public changeClientOnLoadLink(pageName?: string) {
    const digitalData = {
      pageName,
      overlayName: 'change_client',
      overlayDisplayed: 'overlay_displayed',
      linkName: '',
      linkInteraction: undefined
    };
    this.taggingService.link(digitalData);
  }


  // Link: On click of ‘open dashboard'
  // https://cvsdigital.atlassian.net/wiki/spaces/EMT/pages/3472392467/myPBM+Web+Tag+Spec#Link-Tracking.44
  public changeClientGoToDashboardLink(pageName?: string) {
    const digitalData: LinkEvent = {
      linkName: 'mypbm|change_client|open_dashboard',
      linkInteraction: '1',
      pageName,
      overlayName: 'change_client'
    };
    this.taggingService.link(digitalData);
  }

  // Link: On click of ‘close'
  // https://cvsdigital.atlassian.net/wiki/spaces/EMT/pages/3472392467/myPBM+Web+Tag+Spec#Link-Tracking.44
  public changeClientCloseLink(pageName?: string) {
    const digitalData: LinkEvent = {
      linkName: 'mypbm|change_client|close',
      linkInteraction: '1',
      pageName,
      overlayName: 'change_client'
    };
    this.taggingService.link(digitalData);
  }

  // Link: On click of ‘cancel'
  // https://cvsdigital.atlassian.net/wiki/spaces/EMT/pages/3472392467/myPBM+Web+Tag+Spec#Link-Tracking.44
  public changeClientCancelLink(pageName?: string) {
    const digitalData: LinkEvent = {
      linkName: 'mypbm|change_client|cancel',
      linkInteraction: '1',
      pageName,
      overlayName: 'change_client'
    };
    this.taggingService.link(digitalData);
  }

  // Link: On click of ‘dashboard’ on globalnav
  public headerNavDashboardLink(pageName?: string) {
    const digitalData: LinkEvent = {
      linkName: `${HEADER_NAV}dashboard`,
      linkInteraction: '1',
      pageName
    };
    this.taggingService.link(digitalData);
  }

  // Link: On click of ‘client info’on global nav dropdown
  public headerNavClientInfoLink(pageName?: string) {
    const digitalData: LinkEvent = {
      linkName: HEADER_NAV_CLIENT_INFO,
      linkInteraction: '1',
      pageName
    };
    this.taggingService.link(digitalData);
  }

  // Link: On click of ‘client information’, 'client contacts',
  // 'cvs/caremark contacts','vendor contacts’, ‘consultant contacts’ on global nav dropdown
  public headerNavClientInfoSubLink(
    subLink: ClientInfoSubLink,
    pageName?: string
  ) {
    const digitalData: LinkEvent = {
      linkName: `${HEADER_NAV_CLIENT_INFO}|${subLink}`,
      linkInteraction: '1',
      pageName
    };
    this.taggingService.link(digitalData);
  }

  // Link: On click of ‘clinical’ on globalnav
  public headerNavClinicalLink(pageName?: string) {
    const digitalData: LinkEvent = {
      linkName: `${HEADER_NAV}clinical_plans`,
      linkInteraction: '1',
      pageName
    };
    this.taggingService.link(digitalData);
  }

  // Link: On click of ‘Clinical Plan management’, ‘Formulary drug & search’ on global navigation dropdown
  public headerNavClinicalSubLink(
    subLink: 'cpm' | 'fds',
    pageName?: string
  ) {
    const digitalData: LinkEvent = {
      linkName: `${HEADER_NAV}clinical_plans|${subLink}`,
      linkInteraction: '1',
      pageName
    };
    if (subLink === 'cpm') {
      digitalData.linkExtraInfo = 'cpm_header';
    }
    this.taggingService.link(digitalData);
  }

  // Link: On click of ‘benefit plans’ on globalnav
  public headerNavBenefitsLink(pageName?: string) {
    const digitalData: LinkEvent = {
      linkName: `${HEADER_NAV}benefit_plans`,
      linkInteraction: '1',
      pageName,
      linkExtraInfo: 'bps_header'
    };
    this.taggingService.link(digitalData);
  }

  // Link: On click of ‘member eligibility’ on globalnav
  public headerNavEligibilityLink(pageName?: string) {
    const digitalData: LinkEvent = {
      linkName: `${HEADER_NAV}member_eligibility`,
      linkInteraction: '1',
      pageName
    };
    this.taggingService.link(digitalData);
  }

  // Link: On click of ‘Reporting’ on globalnav
  // https://cvsdigital.atlassian.net/wiki/spaces/EMT/pages/3472392467/myPBM+Web+Tag+Spec#Link-Tracking.45
  public headerNavReportingLink(pageName?: string) {
    const digitalData: LinkEvent = {
      linkName: `${HEADER_NAV}reporting`,
      linkInteraction: '1',
      pageName
    };
    this.taggingService.link(digitalData);
  }

  // Link: On click of ‘Notification bell’ on globalnav
  // https://cvsdigital.atlassian.net/wiki/spaces/EMT/pages/3472392467/myPBM+Web+Tag+Spec#Link-Tracking.46
  public headerNavNotificationLink(pageName?: string) {
    const digitalData: LinkEvent = {
      linkName: `${HEADER_NAV}notification_center`,
      linkInteraction: '1',
      pageName
    };
    this.taggingService.link(digitalData);
  }

  // Link: On click of ‘Clinical Plan management’, ‘Formulary drug & search’ on global navigation dropdown
  // https://cvsdigital.atlassian.net/wiki/spaces/EMT/pages/3472392467/myPBM+Web+Tag+Spec#Link-Tracking.46
  public headerNavNotificationSubLink(
    subLink: 'view_document' | 'delete' | 'clear' | 'close',
    pageName?: string
  ) {
    const digitalData: LinkEvent = {
      linkName: `${HEADER_NAV}notification_center|${subLink}`,
      linkInteraction: '1',
      pageName
    };
    if (subLink === 'view_document') {
      digitalData.linkExtraInfo = 'notification_center';
    }
    this.taggingService.link(digitalData);
  }
}
