import { Injectable } from '@angular/core';
import { Observable, concatMap, map } from 'rxjs';
import { AuthenticationService, AuthorizationService } from 'angular-component-library';
import { ClientModel, ShellContext, UserModel, GoLiveTracker, ProgressEntry } from '@pbm-shared/pbm-shared-models';
import { ClientService } from '../client/client.service';


@Injectable({
  providedIn: 'root'
})
export class ShellContextService {
  selectedClient: ClientModel;
  user: UserModel;
  goLiveTracker: GoLiveTracker;
  progressTracker: ProgressEntry[];

  constructor(
    private clientService: ClientService,
    private authorizationService: AuthorizationService,
    private authenticationService: AuthenticationService,
  ) { }

  getClientAndUser() {
    return this.clientService.selectedClient$.pipe(
      concatMap(client => {
        this.selectedClient = client;
        return this.authorizationService.getAuthorizedUser().pipe(
          map(user => {
            this.user = user;
            const context = {
              containerId: 'e2e',
              authInfo: {
                user: this.user,
                idToken: this.authenticationService.getIdToken(),
                accessToken: this.authenticationService.getAccessToken()
              },
              selectedClient: this.selectedClient
            };
            return context;
          })
        )
      })
    )
  }

  getContext(): Observable<ShellContext> {
    return this.getClientAndUser();
  }
}